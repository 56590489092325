* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px, 10px/16px = 62.5% */
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

@-webkit-keyframes imageAnimation { 
  0% { opacity: 0;
  -webkit-animation-timing-function: ease-in; }
  8% { opacity: 1;
       -webkit-animation-timing-function: ease-out; }
  17% { opacity: 1 }
  25% { opacity: 0 }
  100% { opacity: 0 }
}

@-moz-keyframes imageAnimation { 
  0% { opacity: 0;
  -moz-animation-timing-function: ease-in; }
  8% { opacity: 1;
       -moz-animation-timing-function: ease-out; }
  17% { opacity: 1 }
  25% { opacity: 0 }
  100% { opacity: 0 }
}

@-o-keyframes imageAnimation { 
  0% { opacity: 0;
  -o-animation-timing-function: ease-in; }
  8% { opacity: 1;
       -o-animation-timing-function: ease-out; }
  17% { opacity: 1 }
  25% { opacity: 0 }
  100% { opacity: 0 }
}

@-ms-keyframes imageAnimation { 
  0% { opacity: 0;
  -ms-animation-timing-function: ease-in; }
  8% { opacity: 1;
       -ms-animation-timing-function: ease-out; }
  17% { opacity: 1 }
  25% { opacity: 0 }
  100% { opacity: 0 }
}

@keyframes imageAnimation { 
  0% { opacity: 0;
  animation-timing-function: ease-in; }
  8% { opacity: 1;
       animation-timing-function: ease-out; }
  17% { opacity: 1 }
  25% { opacity: 0 }
  100% { opacity: 0 }
}

@keyframes cf4FadeInOut {
  0% {
    opacity:1;
  }
  22.66% {
    opacity:1;
  }
  33.3% {
    opacity:0;
  }
  89.33% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.loader--hide {
  opacity: 0;
}

.preloader {
  width: 100%;
  height: 100vh;
  background-color: rgba(178, 193, 233, 0.301);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}

.rotate {
  animation: loader 1.2s linear infinite;
}

.loader-hide {
  display: none;
}

@keyframes loader {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* END PRELOADER */

@keyframes loading {
  0% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(-20px);
  }
}
